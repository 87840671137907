const $ = require("jquery");

// =============================================================
// POSITION HEADER HOMEPAGE
// =============================================================

$(document).ready(function() {
  let mainMenuHomepage = $('.homepage-menu');
  let headerBanners = $('#header-banners');

  function updateMainMenuPosition() {
    mainMenuHomepage.css('top', headerBanners.height() + 'px');
  }

  updateMainMenuPosition();
});
